<script>
  import Login from "./Login.svelte";
  import Form from "./Form.svelte";
  import { apiUrl, schemaId, apiKey } from "./stores";
  import { onMount } from "svelte";

  onMount(() => {
    $schemaId = localStorage.getItem("schema");
    $apiUrl = localStorage.getItem("apiUrl");
  });
</script>

<main>
  {#if $apiUrl && $schemaId && $apiKey}
    <Form />
  {:else}
    <Login />
  {/if}
</main>
