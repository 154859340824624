<script>
  import { apiUrl, schemaId, apiKey } from "./stores";
  let formApiUrl;
  let formApiKey;
  let formSchemaId;
  let error;

  $: formApiUrl = $apiUrl;
  $: formSchemaId = $schemaId;

  const login = async () => {
    const api = formApiUrl + "?secret=" + formApiKey;
    const login = await fetch(api);
    if (login.status !== 401) {
      error = undefined;
      $apiUrl = api;
      $apiKey = formApiKey;
      $schemaId = formSchemaId;

      localStorage.setItem("apiUrl", formApiUrl);
      localStorage.setItem("schema", formSchemaId);
    } else {
      error = "Invalid API secret";
    }
  };
</script>

<svelte:head>
  <link rel="stylesheet" href="login.css" />
</svelte:head>

<div class="login">
  <h1 style="margin-bottom: 25px;">Login</h1>
  <form on:submit|preventDefault={login}>
    <input type="text" name="u" placeholder="Concierge API URL" bind:value={formApiUrl} required="required" />
    <input type="password" name="p" placeholder="API Key" bind:value={formApiKey} required="required" />
    <input type="text" name="s" placeholder="Schema" required="required" bind:value={formSchemaId} />
    <button type="submit" class="login-btn login-btn-primary login-btn-block login-btn-large">Log in</button>
  </form>
  {#if error}
    <p style="color: red;">{error}</p>
  {/if}
</div>

<div class="login-bg" />

<style>
  .login-bg {
    position: fixed;
    z-index: -9999;
    width: 100vw;
    height: 100vh;
    width: 100%;
    height: 100%;
    font-family: "Open Sans", sans-serif;
    background: #092756;
    background: -moz-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%),
      -moz-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%),
      -moz-linear-gradient(-45deg, #670d10 0%, #092756 100%);
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%),
      -webkit-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%),
      -webkit-linear-gradient(-45deg, #670d10 0%, #092756 100%);
    background: -o-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%),
      -o-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%),
      -o-linear-gradient(-45deg, #670d10 0%, #092756 100%);
    background: -ms-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%),
      -ms-linear-gradient(top, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%),
      -ms-linear-gradient(-45deg, #670d10 0%, #092756 100%);
    background: -webkit-radial-gradient(0% 100%, ellipse cover, rgba(104, 128, 138, 0.4) 10%, rgba(138, 114, 76, 0) 40%),
      linear-gradient(to bottom, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100%),
      linear-gradient(135deg, #670d10 0%, #092756 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3E1D6D', endColorstr='#092756',GradientType=1 );
  }
</style>
