<script>
  import { createEventDispatcher } from "svelte";
  import { deleteById, getById } from "./helpers";
  const dispatch = createEventDispatcher();
  let id = "";
  let res;
  export let error;
  let deleted;

  const get = async () => {
    console.log('get');
    res = await getById(id);
    if (!res) {
      error = "Entry could not be found.";
    } else {
      error = undefined;
      dispatch("result", res);
    }
  };

  const del = (id) => {
    deleted = deleteById(id);
    if (deleted) {
      error = id + " deleted.";
    }
    dispatch("delete", id);
  };
</script>

<form on:submit|preventDefault={get} style="display: flex;">
  <input
    type="text"
    placeholder="Search by ID"
    class="form-control"
    bind:value={id}
  />
  <button type="submit" class="btn btn-primary" style="background-color: rgb(89, 98, 223); margin-left: 10px;">
    Get
  </button>
  {#if res}
    <button
      class="btn btn-danger"
      style="margin-left: 10px;"
      on:click|preventDefault={del(id)}
    >
      Delete
    </button>
  {/if}
</form>
{#if error}
  <p style="color: red;">{error}</p>
{/if}
