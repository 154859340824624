<script>
  import { getSchema } from "./helpers";
  import { onMount } from "svelte";
  import { JSONEditor } from "@json-editor/json-editor";
  import { apiUrl, schemaId } from "./stores";
  import Search from "./Search.svelte";

  let editor;
  let searchError;
  let successMsg;

  $: if (searchError) {
    successMsg = undefined;
  }

  const handleResult = (event) => {
    successMsg = undefined;
    const result = event.detail;
    if (editor) {
      editor.setValue(result);
    }
  };
  onMount(async () => {
    const schema = JSON.parse(await getSchema($schemaId));
    if (schema.properties._primaryKey) {
      delete schema.properties._primaryKey;
    }
    // Initialize the editor with a JSON schema
    editor = new JSONEditor(document.getElementById("editor"), {
      schema,
      theme: "bootstrap4",
      disable_collapse: true,
      disable_edit_json: true,
      disable_properties: true,
      iconlib: "fontawesome4",
      prompt_before_delete: false,
      show_errors: "change",
    });
  });
  const submit = async () => {
    searchError = undefined;
    successMsg = undefined;
    const data = editor.getValue();
    const errors = editor.validate();
    if (!errors.length) {
      const res = await fetch($apiUrl, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (res.status === 200) {
        successMsg = "Saved successfully.";
      }
    } else {
      console.log("Error!");
      console.log(errors);
    }

    console.log(data);
  };
</script>

<div style="padding: 24px; max-width: 1000px; margin: 0 auto;">
  <div style="margin-bottom:1rem">
    <Search on:result={handleResult} bind:error={searchError} />
  </div>
  <div id="editor" />
  <button
    class="btn btn-primary"
    on:click={submit}
    style="background-color: rgb(89, 98, 223); color: white; padding: 12px; width: 100%;">Submit</button
  >
  {#if successMsg}
    <p style="color: green;">{successMsg}</p>
  {/if}
</div>
