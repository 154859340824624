import IPFS from "ipfs-mini";
import { get } from "svelte/store";
import { apiUrl } from "./stores";
import { config } from "./config"
export async function getIpfs(hash, maxFileSize = 30000) {
    let size;
    const ipfs = new IPFS({
        host: "gateway.ipfs.io",
        port: "443",
        protocol: "https"
    });

    try {
        const stat = await ipfs.stat(hash);
        size = stat.CumulativeSize;
    } catch (e) {
        console.log(e);
    }

    if (size && size <= maxFileSize) {
        try {
            const content = await fetch(
                `https://cloudflare-ipfs.com/ipfs/${hash}/`
            );
            if (content.status === 200) {
                return await content.text();
            }
        } catch (error) {
            console.log(error);
        }
    }
}

export async function getSchema(schemaId) {
    // const api = get(apiUrl);
    console.log(config.relay + '/schemas/' + schemaId);
    const content = await fetch(config.relay + '/schemas/' + schemaId);
    if (content.status === 200) {
        const res = JSON.parse(await content.text());
        if (res && Object.values(res.data).length && res.data.ipfs) {
            const hash = res.data.ipfs;
            return await getIpfs(hash);
        }
    }
    return false;
}

export async function getById(id) {
    const api = get(apiUrl);
    const content = await fetch(api + `&id=${id}`);
    if (content.status === 200) {
        const res = JSON.parse(await content.text());
        if (Object.values(res).length) {
            console.log(res);
            return res;
        }
    }
    return undefined;
}

export async function deleteById(id) {
    const api = get(apiUrl);
    const res = await fetch(api, {
        method: "DELETE", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
    });
    console.log(res.status);
    if (res.status === 200) {
        return true;
    }
    return false;
}